import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Media from "components/Media";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "media"
    }}>{`Media`}</h1>

    <Playground __position={0} __code={'<Media>\n  <Media left href=\"#\">\n    <Media object alt=\"Image\" />\n  </Media>\n  <Media body>\n    <Media heading>Media heading</Media>\n    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque\n    ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at,\n    tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.\n    Donec lacinia congue felis in faucibus.\n  </Media>\n</Media>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Media,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Media mdxType="Media">
    <Media left href="#" mdxType="Media">
      <Media object alt="Image" mdxType="Media" />
    </Media>
    <Media body mdxType="Media">
      <Media heading mdxType="Media">
        Media heading
      </Media>
      Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
    </Media>
  </Media>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/media/" target="_blank">Reactstrap Media</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      